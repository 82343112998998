import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Container, Divider, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Collapse } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAtom } from 'jotai';
import { currentUserUidAtom } from './atoms';
import Header from './Header';
import { Community, deleteCommunity, onOwnedCommunitiesSnapshot, setCommunity } from './database';

const validationSchema = yup.object({
  link: yup
    .string()
    .url('http(s)://를 포함하는 유효한 URL을 입력해주세요')
    .required('원본 링크를 입력해주세요'),
  slug: yup
    .string()
    .min(2, '최소 2자 이상을 입력해주세요')
    .matches(/^[a-zA-Z0-9-_]+$/, '알파벳, 숫자, -_만 포함할 수 있어요')
    .required('단축 주소를 입력해주세요'),
  name: yup
    .string()
    .max(40, '최대 40자까지 입력할 수 있어요'),
  description: yup
    .string()
    .max(140, '최대 140자까지 입력할 수 있어요'),
});

const Dashboard: React.FC = () => {
  const [userUid] = useAtom(currentUserUidAtom)
  
  const [submitting, setSubmitting] = useState(false);
  
  const [rows, setRows] = useState<Community[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const snackbarOpen = snackbarMessage !== null;

  /*
  * 입력 폼
  */
  const formik = useFormik({
    initialValues: {
      link: '',
      slug: '',
      name: '',
      description: '',
      listed: false,
      createDate: new Date(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleListedChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('listed', event.target.checked);
  }, [formik]);

  /*
  * 커뮤니티 생성 / 수정 / 삭제
  */
  const handleSubmit = useCallback(async (payload: Omit<Community, 'owner'>) => {
    if (!userUid) {
      return;
    }

    setSubmitting(true);
    try {
      await setCommunity({ ...payload, owner: userUid });
      formik.resetForm();
      setSnackbarMessage('성공적으로 등록했어요');
    } catch(e) {
      const message = (e as unknown as Error).message;
      if (message.includes('permission')) {
        alert('이미 존재하는 단축 주소에요')
      } else {
        alert(message);
      }
    } finally {
      setSubmitting(false);
    }
  }, [formik, userUid]);

  const handleDelete = useCallback(async (slug: string) => {
    try {
      await deleteCommunity(slug);
      setSnackbarMessage(`/${slug} 링크를 삭제했어요`)
    } catch(e) {
      const message = (e as unknown as Error).message;
      alert(message);
    }
  }, []);

  /*
  * 내가 등록한 리스트 관련 로직
  */
  useEffect(() => {
    if (!userUid) {
      return;
    }
    return onOwnedCommunitiesSnapshot(userUid, setRows);
  }, [userUid]);

  const handleCopy = useCallback(async (text: string) => {
    await navigator.clipboard.writeText(text);
    setSnackbarMessage('링크를 클립보드로 복사했어요')
  }, []);

  const handleEditClick = useCallback(async (item: Community) => {
    formik.setValues({
      link: item.link,
      slug: item.slug,
      name: item.name ?? '',
      description: item.description ?? '',
      listed: Boolean(item.listed),
      createDate: item.createDate,
    }, true);
    const anchor = document.querySelector('#community-form');
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [formik]);

  const handleSnackbarClose = useCallback((event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage(null);
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth="md">
        <Box marginTop={2} marginBottom={1}>
          <Typography variant="h5">
            URL 등록
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} id="community-form">
          <Stack spacing={2} maxWidth={400}>
            <TextField
              id="link"
              name="link"
              label="원본 링크"
              variant="outlined"
              type="link"
              size="small"
              value={formik.values.link}
              onChange={formik.handleChange}
              error={formik.touched.link && Boolean(formik.errors.link)}
              helperText={formik.touched.link && formik.errors.link}
            />
            <TextField
              label="단축 주소"
              variant="outlined"
              id="slug"
              name="slug"
              value={formik.values.slug}
              onChange={formik.handleChange}
              error={formik.touched.slug && Boolean(formik.errors.slug)}
              helperText={formik.touched.slug && formik.errors.slug}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">mogak.co/</InputAdornment>,
              }}
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={formik.values.listed} onChange={handleListedChange}/>} label="공개 리스트에 등록" />
              <FormHelperText>어썸 모각코 리스트에 등록하면 다른 사람에게 이름과 설명, 링크가 공개됩니다.</FormHelperText>
            </FormGroup>
            <Collapse in={formik.values.listed}>
              <Stack spacing={2}>
                <TextField
                  id="name"
                  name="name"
                  label="이름"
                  variant="outlined"
                  size="small"
                  disabled={!formik.values.listed}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  label="설명"
                  variant="outlined"
                  id="description"
                  name="description"
                  size="small"
                  disabled={!formik.values.listed}
                  value={formik.values.description}
                  onChange={(event) => {
                    event.preventDefault();
                    formik.handleChange(event);
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={(formik.touched.description && formik.errors.description) || "어떤 커뮤니티인가요? (최대 140자)"}
                  multiline
                  maxRows={2}
                  minRows={4}
                />
              </Stack>
            </Collapse>
            <Typography variant='caption'>
              내가 이미 등록한 URL이 있다면 덮어씁니다.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              disabled={submitting}
            >
              등록
            </Button>
          </Stack>
        </form>
        <Box marginTop={4} marginBottom={4}>
          <Divider />
        </Box>
        <Box marginBottom={1}>
          <Typography variant="h5">
            내가 등록한 URL
          </Typography>
        </Box>
        <TableContainer component="div">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>단축 주소</TableCell>
                <TableCell align="right">원본 링크</TableCell>
                <TableCell align="right">수정/삭제</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.slug}>
                  <TableCell>
                    <Typography variant='caption'>mogak.co/{row.slug}</Typography>
                    <IconButton aria-label="복사" onClick={() => handleCopy(`https://mogak.co/${row.slug}`)}>
                      <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant='caption'>{row.link}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="수정" onClick={() => handleEditClick(row)}>
                      <EditIcon fontSize="small"/>
                    </IconButton>
                    <IconButton aria-label="삭제" onClick={() => handleDelete(row.slug)}>
                      <DeleteIcon fontSize="small"/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
        <Box marginTop={6} marginBottom={6}>
          <Typography variant='caption'>문의/버그제보: twitter </Typography>
          <a href="https://twitter.com/kms_bernard" target="_blank" rel="noreferrer">
            <Typography variant='caption'>@kms_bernard</Typography>
          </a>
        </Box>
      </Container>
    </>
  );
}

export default Dashboard;
