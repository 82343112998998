import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import SignUp from './SignUp';
import FindPassword from './FindPassword';
import { getAuth } from 'firebase/auth';
import { useAtom } from 'jotai';
import { currentUserUidAtom } from './atoms';

const firebaseConfig = {
  apiKey: "AIzaSyCg0hk0nExsbOxUafFDA0P7n9T6egX3VwY",
  authDomain: "mogak-co.firebaseapp.com",
  projectId: "mogak-co",
  storageBucket: "mogak-co.appspot.com",
  messagingSenderId: "463757434844",
  appId: "1:463757434844:web:95efd8f020dd59505b7919",
  measurementId: "G-EBRFHKZW0K"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-restricted-globals
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ldna-QeAAAAAAvUQwvcSLdWa4kHfdMACdn69bsL'),
  isTokenAutoRefreshEnabled: true,
});

const Root: React.FC = () => {
  const auth = getAuth();
  const [, setCurrentUserUid] = useAtom(currentUserUidAtom)

  useEffect(() => {
    if (auth.currentUser) {
      setCurrentUserUid(auth.currentUser.uid);
    }
    return auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserUid(user.uid);
      } else {
        setCurrentUserUid(null);
      }
    })
  }, [auth, setCurrentUserUid]);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/find-password" element={<FindPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
