import { useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Divider, Toolbar, Typography } from "@mui/material"
import { useAtom } from 'jotai';
import { authenticatedAtom } from './atoms';

const Header: React.FC = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [authenticated] = useAtom(authenticatedAtom);

  const handleSignOut = useCallback(async () => {
    await auth.signOut();
    navigate('/');
  }, [auth, navigate]);

  return (
    <AppBar position="relative" elevation={0} color="transparent">
      <Toolbar>
        <Button variant="text" color="inherit" href='/'>
          <Typography variant="h5" fontWeight={700}>어썸 모각코</Typography>
          <Typography variant="subtitle2" marginLeft={1}>BETA</Typography>
        </Button>
        <Box flexGrow={1} />
        {authenticated && (
          <Button
            variant="text"
            disableElevation
            onClick={handleSignOut}
          >
            로그아웃
          </Button>
        )}
      </Toolbar>
      <Divider/>
    </AppBar>
  )
}

export default Header;
