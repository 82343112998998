import { collection, deleteDoc, doc, DocumentData, getDocs, getFirestore, onSnapshot, query, QueryDocumentSnapshot, setDoc, where } from "firebase/firestore";

export interface Community {
  link: string;
  slug: string;
  owner: string;
  name?: string;
  description?: string;
  listed?: boolean;
  createDate: Date;
}

const mapCommunity = (snapshot: QueryDocumentSnapshot<DocumentData>): Community => {
  const data = snapshot.data();
  return {
    link: data.link,
    slug: data.slug,
    owner: data.owner,
    listed: data.listed,
    name: data.name,
    description: data.description,
    createDate: data.createDate.toDate(),
  }
}

export const onOwnedCommunitiesSnapshot = (userUid: string, onNext: (items: Community[]) => void) => {
  const db = getFirestore();
  const communityCollection = collection(db, 'communities');
  const communitiesQuery = query(communityCollection, where('owner', '==', userUid))
  return onSnapshot(communitiesQuery, (snapshot) => {
    const communities: Community[] = snapshot.docs.map(mapCommunity);
    communities.sort((a, b) => b.createDate.valueOf() - a.createDate.valueOf());
    onNext(communities);
  });
}

export const getListedCommunities = async (): Promise<Community[]> => {
  const db = getFirestore();
  const communityCollection = collection(db, 'communities');
  const communitiesQuery = query(communityCollection, where('listed', '==', true));
  const querySnapshot = await getDocs(communitiesQuery);
  const communities = querySnapshot.docs.map(mapCommunity);
  communities.sort((a, b) => b.createDate.valueOf() - a.createDate.valueOf());
  return communities;
}

const invalidateSlug = async (slug: string) => {
  await fetch(`https://mogak.co/${slug}`, {
    method: "post",
    mode: 'no-cors',
  });
};

export const setCommunity = async (payload: Community) => {
  const db = getFirestore();
  const communityCollection = collection(db, 'communities');
  const docRef = doc(communityCollection, payload.slug);
  await invalidateSlug(payload.slug);
  await setDoc(docRef, payload);
}

export const deleteCommunity = async (slug: string) => {
  const db = getFirestore();
  const communityCollection = collection(db, 'communities');
  const docRef = doc(communityCollection, slug);
  await invalidateSlug(slug);
  await deleteDoc(docRef);
}