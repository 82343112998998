import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Divider, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, GithubAuthProvider, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai'
import { authenticatedAtom } from './atoms';
import Header from './Header';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('이메일을 입력해주세요')
    .required('이메일을 입력해주세요'),
  password: yup
    .string()
    .required('비밀번호를 입력해주세요'),
});

const SignIn: React.FC = () => {
  const auth = getAuth();
  auth.useDeviceLanguage();
  const [authenticated] = useAtom(authenticatedAtom);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const navigateToDashboard = useCallback(() => navigate('/dashboard', { replace: true }), [navigate]);

  useEffect(() => {
    if (authenticated) {
      navigateToDashboard();
    }
  }, [authenticated, navigateToDashboard]);

  useEffect(() => {
    setLoading(true);
    getRedirectResult(auth).catch((error) => {
      const errorMessage = error.message;
      alert(errorMessage);
    }).finally(() => {
      setLoading(false);
    });
  }, [auth]);

  const handleSignIn = async (email: string, password: string) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch(e) {
      const message = (e as unknown as Error).message;
      if (message.includes('auth/user-not-found')) {
        alert('존재하지 않는 유저입니다');
      } else if (message.includes('auth/wrong-password')) {
        alert('잘못된 비밀번호입니다')
      } else {
        alert(message);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleSinInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  }

  const handleSinInWithGitHub = () => {
    const provider = new GithubAuthProvider();
    signInWithRedirect(auth, provider);
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSignIn(values.email, values.password);
    },
  });

  return (
    <>
      <Header />
      <Container maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            height: '80vh'
          }}
        >
          <Grid item xs={12} sm={9}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <Typography variant='h5'>
                  로그인
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  startIcon={<GoogleIcon />}
                  onClick={handleSinInWithGoogle}
                  disabled={loading}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  Google로 로그인
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  startIcon={<GitHubIcon />}
                  onClick={handleSinInWithGitHub}
                  disabled={loading}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  GitHub으로 로그인
                </Button>
                <Divider>
                  <Typography variant='caption'>또는</Typography>
                </Divider>
                <TextField
                  id="email"
                  name="email"
                  label="이메일"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={loading}
                />
                <TextField
                  label="비밀번호"
                  variant="outlined"
                  id="password"
                  name="password"
                  type="password"
                  size="small"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  disabled={loading}
                />
                <Link href="/find-password">
                  <Typography variant="caption">비밀번호를 잊으셨나요?</Typography>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={loading}
                >
                  로그인
                </Button>
                <Button
                  variant="text"
                  disableElevation
                  endIcon={<ArrowForwardIcon />}
                  href="/sign-up"
                >
                  이메일로 회원가입
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SignIn
