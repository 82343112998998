import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Skeleton, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { TextLoop } from "react-text-loop-next";
import { useAtom } from 'jotai'
import { currentUserUidAtom } from './atoms';
import Header from './Header';
import { Community, getListedCommunities } from './database';

const App: React.FC = () => {
  const [currentUserUid] = useAtom(currentUserUidAtom)
  const [rows, setRows] = useState<Community[]>([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLoading(true);
    getListedCommunities().then((data) => {
      setRows(data);
      setLoading(false);
    });
  }, []);
  
  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Box marginTop={3} marginBottom={3}>
          <Stack direction="row">
            <Typography variant="h4" fontWeight={500}>
              mogak.co/
              <TextLoop interval={1500}>
                <span>seoul</span>
                <span>pohang</span>
                <span>coffee</span>
                <span>cats</span>
                <span>super</span>
                <span>asdf</span>
                <span>0to1</span>
                <span>gloom</span>
              </TextLoop>
            </Typography>
          </Stack>
        </Box>
        <Typography variant='body1'>
          커뮤니티를 위한 URL 단축 서비스를 제공합니다.
        </Typography>
        <Stack spacing={2} marginTop={3} marginBottom={4}>
          {currentUserUid && (
            <>
              <Button
                variant="contained"
                disableElevation
                endIcon={<ArrowForwardIcon />}
                href="/dashboard"
                size='large'
              >
                대시보드
              </Button>
            </>
          )}
          {!currentUserUid && (
            <>
              <Button
                variant="contained"
                disableElevation
                endIcon={<ArrowForwardIcon />}
                href="/sign-in"
                size='large'
              >
                등록하기
              </Button>
            </>
          )}
          <Button
            variant='text'
            disableElevation
            endIcon={<OutboundOutlinedIcon />}
            href="https://github.com/utilForever/awesome-cafe"
            target="_blank"
          >
            모각코하기 좋은 카페 리스트
          </Button>
        </Stack>
        <Stack spacing={2} marginBottom={6}>
          <Divider>
            <Typography variant='h6'>커뮤니티</Typography>
          </Divider>
          <Typography variant='caption'>단축 URL 등록 시 공개를 희망한 커뮤니티가 보입니다. (최근 등록 순)</Typography>
          {loading && (
            <Stack spacing={1}>
              <Skeleton variant="rectangular" width={150} height={20}/>
              <Skeleton variant="rectangular" width="100%" height={80} />
            </Stack>
          )}
          {!loading && (
            <ul>
              {rows.map((row) => (
                <li key={row.slug}>
                  <Typography variant='body1'>{row.name}</Typography>
                  <ul>
                    <li><Typography variant='body1'>{row.description}</Typography></li>
                    <li><a href={`https://mogak.co/${row.slug}`} target="_blank" rel="noreferrer"><Typography variant='body1'>mogak.co/{row.slug}</Typography></a></li>
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </Stack>
        <Typography variant='caption'>문의/버그제보: twitter </Typography>
        <a href="https://twitter.com/kms_bernard" target="_blank" rel="noreferrer">
          <Typography variant='caption'>@kms_bernard</Typography>
        </a>
      </Container>
    </>
  );
}

export default App;
