import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useAtom } from 'jotai';
import { authenticatedAtom } from './atoms';
import Header from './Header';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('이메일을 입력해주세요')
    .required('이메일을 입력해주세요'),
  password: yup
    .string()
    .min(8, '비밀번호는 최소 8자 이상이어야해요')
    .required('비밀번호를 입력해주세요'),
});

const SignUp: React.FC = () => {
  const auth = getAuth();
  const [authenticated] = useAtom(authenticatedAtom);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const navigateToDashboard = useCallback(() => navigate('/dashboard', { replace: true }), [navigate]);

  useEffect(() => {
    if (authenticated) {
      navigateToDashboard();
    }
  }, [authenticated, navigateToDashboard]);

  const handleCreateUser = async (email: string, password: string) => {
    try {
      setLoading(true);
      await createUserWithEmailAndPassword(auth, email, password);
    } catch(e) {
      alert((e as unknown as Error).message);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleCreateUser(values.email, values.password);
    },
  });

  return (
    <>
      <Header />
      <Container maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            height: '80vh'
          }}
        >
          <Grid item xs={12} sm={9}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <Typography variant='h5'>
                  회원가입
                </Typography>
                <TextField
                  id="email"
                  name="email"
                  label="이메일"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  label="비밀번호"
                  variant="outlined"
                  id="password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  size="small"
                />
                <Typography variant="caption">수집된 이메일은 비밀번호를 찾는 과정에서 본인확인을 위한 용도로만 사용됩니다.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={loading}
                >
                  회원가입
                </Button>
                <Button
                  variant="text"
                  disableElevation
                  startIcon={<ArrowBack />}
                  href="/sign-in"
                >
                  로그인
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SignUp
