import React, { useState } from 'react';
import { Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import * as yup from 'yup';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useFormik } from 'formik';
import Header from './Header';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('이메일을 입력해주세요')
    .required('이메일을 입력해주세요'),
});

const FindPassword: React.FC = () => {
  const auth = getAuth();
  const [loading, setLoading] = useState(false);

  const handleFindPassword = async (email: string) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
      alert('비밀번호 재설정 이메일을 보냈어요.');
    } catch(e) {
      alert((e as unknown as Error).message);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFindPassword(values.email);
    },
  });
  
  return (
    <>
      <Header />
      <Container maxWidth="xs">\
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            height: '80vh'
          }}
        >
          <Grid item xs={12} sm={9}>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <Typography variant='h5'>
                  비밀번호 찾기
                </Typography>
                <Typography variant="body2">가입하신 이메일 주소로 비밀번호 재설정 링크를 보내드려요.</Typography>
                <TextField
                  id="email"
                  name="email"
                  label="이메일"
                  variant="outlined"
                  type="email"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={loading}
                >
                  보내기
                </Button>
                <Button
                  variant="text"
                  disableElevation
                  startIcon={<ArrowBack />}
                  href="/sign-in"
                >
                  로그인
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default FindPassword
